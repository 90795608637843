<template>
    <div class="dashboard">
        <el-row :gutter="15">
            <el-col :md="6" :sm="12" v-for="(item,index) in infoBox" class="info-wrap" :key="index">
                    <div class="info-box">
                        <span class="info-box-icon"><i :class="item.icon"></i></span>
                        <div class="info-box-count">
                            <span class="number">{{item.num}}</span>
                            <span>{{item.text}}</span>
                        </div>
                    </div>
            </el-col>
        </el-row>
        <el-row :gutter="15" class="row-wrap">
                <el-col :xs="24" :sm="12">
                    <defaultSec :title="'SCRM-素材审核列表'">
                        <div class="check-list-wrap">
                            <template v-for="item in checkMaterialsList">
                                <div class="check-list">
                                    <p class="overflow-info">
                                        <span style="color: #e85656">●</span> {{item.materialName}}
                                        <span style="color: #999999;" v-if="item.pushTextPre">（{{item.pushTextPre}}）</span>
                                    </p>
                                    <el-button style="margin-left: 10px;font-weight: 600" size="mini" type="text" icon="el-icon-s-check" @click="editMaterial(item)">审核</el-button>
                                </div>
                            </template>
                            <div v-if="checkMaterialsList.length<=0" style="text-align: center;color: #d4d4d4;padding-top: 150px">
                                <div><i class="el-icon-box" style="font-size: 50px"></i></div>
                                暂无
                            </div>
                        </div>

                    </defaultSec>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <defaultSec :title="'CALENDAR'">
                        <div>
                            <div id="myChart1" class="chart-part"></div>
                        </div>
                    </defaultSec>
                </el-col>

        </el-row>
        <el-drawer :visible.sync="showExampleDialog" title="素材管理【待审核】" size="75%">

            <materialDetail ref="materialDetail" @initList="initScrmData"></materialDetail>
        </el-drawer>
    </div>

</template>

<script>
    import defaultSec from '@c/defaultSection'

    import 'echarts/theme/macarons'
    import materialDetail from "@c/scrm/materialDetail";

    export default {
        data() {
            return {
                value: new Date(),
                infoBox:[{
                    color:'yellow',
                    icon:'el-icon-search',
                    text:'素材审核',
                    num:'0'
                },{
                    color:'green',
                    icon:'el-icon-sold-out',
                    text:'SOP应发',
                    num:'0'
                },{
                    color:'red',
                    icon:'el-icon-message',
                    text:'SOP实发',
                    num:'0'
                },{
                    color:'blue',
                    icon:'el-icon-service',
                    text:'SOP回复',
                    num:'0'
                }],
                checkMaterialsList:[],
                showExampleDialog:false
            }
        },
        components:{
            defaultSec,materialDetail
        },
        computed:{
            selectSystemIndex:function(){
                return this.$store.state.navData.menuSelectSystem
            }
        },
        mounted(){
            this.initScrmData()
            this.initScrmCount()
            this.initEchart()
        },
        methods: {
            initEchart(){
                let myChart1 = this.$echarts.init(document.getElementById('myChart1'),'macarons')
                let option1 = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    legend: {
                        left: 'center',
                        bottom: '10',
                        textStyle:{
                            color:'#ffffff'
                        },
                        data: [
                            'Battlefield',
                            'Call Of Duty',
                            'Counter Strike',
                            'Splatoon',
                            'Titanfall'
                        ]
                    },
                    calculable: true,
                    series: [
                        {
                            name: 'FPS GAME DATA',
                            type: 'pie',
                            roseType: 'radius',
                            radius: [15, 140],
                            center: ['50%', '38%'],
                            data: [
                                { value: 1, name: 'Battlefield' },
                                { value: 2, name: 'Call Of Duty' },
                                { value: 0, name: 'Counter Strike' },
                                { value: 5, name: 'Titanfall' },
                                { value: 8, name: 'SOP应发' }
                            ],
                            animationEasing: 'cubicInOut',
                            animationDuration: 2600
                        }
                    ]
                };
                myChart1.setOption(option1);
            },
            initScrmData(){
                this.showExampleDialog = false

                this.$apiGet('lechun-cms/scrmMaterial/getWaittingCheckMaterials',{}).then(res => {
                    this.checkMaterialsList = res
                    this.infoBox[0].num = res.length
                })
            },
            initScrmCount(){
                this.$apiGet('lechun-cms/scrmRoute/getTodaySopCount',{}).then(res => {
                    console.log(res)
                    this.infoBox[1].num = res.shouldSendCount
                    this.infoBox[2].num = res.factSendCount
                    this.infoBox[3].num = res.repeatCount
                })
            },
            /*修改*/
            editMaterial(e){
                this.showExampleDialog = true
                this.$nextTick(()=>{
                    if(e){
                        this.$refs.materialDetail.getSingle(e.id,1)
                    }else{
                        this.$refs.materialDetail.getSingle(null,1)
                    }
                })

            }
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">
@import "../assets/css/mixin";
    .dashboard{
      .default-section-wrap{
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
      }
        .default-body{
            background: transparent !important;
        }
        .el-calendar,.chart-part{
            height: 560px;
        }
        .el-calendar,.el-calendar-table thead th,.el-calendar__title{
            background: transparent;
            color: #ffffff;
        }
        .el-calendar-table .el-calendar-day:hover,.el-calendar-table td.is-selected{
            color: #209e91;
        }
        .el-calendar-table .el-calendar-day{
            height: 73px;
        }
        .info-box{
            display: flex;
            border: none;
            border-radius: 5px;
            position: relative;
            margin-bottom: 24px;
            padding-bottom: 15px;
            box-shadow: 0 5px 5px 0 rgba(0,0,0,.25);
            @include dashboard_info_box_color();
            &-icon{
                float: left;
                width: 90px;
                display: block;
                height: 90px;
                font-size: 45px;
                text-align: center;
                line-height: 90px;
                color: inherit;
            }
            &-count{
                padding:13px 10px;
                text-align: center;
                span{
                    display: block;
                }
                .number{
                    font-size: 30px;
                }
            }
        }
        .info-desc{
            white-space: pre-line;
            color: #333333;
            margin: 5px 0
        }
        .flex-wrap{
            display: flex;
            justify-content: space-between;
        }
        .check-list-wrap{
            height: 560px;
            overflow-y: scroll;
            scrollbar-width: none;
        }
        .check-list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            padding: 4px 16px;
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 15px;
            @include dashboard_check_list_color()
        }
        .overflow-info{
            margin: 0;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

</style>